<template>
  <div class="" style="width: 100%; margin-bottom: 100px;">
    <v-container fluid>
      <v-row>
        <v-col class='d-flex flex-row align-center'>
          <v-btn fab x-small @click="$router.go(-1)">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <span class="d-flex flex-column">
            <h1 style="line-height: 26px; font-size: 26px; text-align: left;" class="ml-2" v-if="data.name">{{data.name}}</h1>
            <span class="ml-2">{{data.id?` PL-${data.id}`:''}}</span>
          </span>
          <v-progress-circular
              indeterminate
              color="green"
              v-if="loader"
              style="margin-left: 10px;"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <div v-if="data && data.metadata" style="margin-bottom: 150px;">
            <div v-for="(item,index) in data.metadata.media" :key="index" class="mr-3 mb-5">
              <div class="d-flex flex-row align-start justify-start" style="width: auto; height: 100px;">
                <a :href="item.url" target="__blank" style="z-index: 1; height: 200px; margin-top: 0px;">
                  <img :src="item.url" alt="" height="100%;">
                </a>
                <confirmedActionButton
                  color="error" 
                  small
                  icon
                  @cb="deletePhoto(index)"
                  fabIcon="mdi-close"
                  :dialogText="'Are you sure you wish to proceed?'"
                />
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- <v-btn class="ml-2" fab x-small color="info" @click="openPrintDialog()"><v-icon>mdi-printer</v-icon></v-btn> -->
          <v-btn class="mr-2" color="info" @click="isEditable = !isEditable">
            <span v-if="this.isEditable">Cancel</span>
            <span v-else>Edit</span>
          </v-btn>
          <span v-if="isEditable || updating">
          <v-btn color="success" class="mr-2" @click="update" :loading="updating" :disabled="!this.data.name">Update</v-btn>
          <v-btn v-if="isAllowed('product','d')" :disabled="updating" color="error" @click="deleteProductA">Delete</v-btn>
        </span>
        </v-col>
      </v-row>
      <v-row>
      </v-row>
      <v-form :disabled="!isEditable">
        <v-row>
          <v-col>
            <span class="d-flex flex-row justify-space-between align-center">
              <v-checkbox
                  v-if="data.type==='simple'"
                  v-model="data.manageStock"
                  label="Manage Stock?"
                  outlined
                  dense
                  hint="Uncheck this if you want to ignore quantity constraints."
                  persistent-hint
              />
            </span>
            <v-text-field
              dense
              label="Name"
              outlined
              v-model="data.name"
              class="mt-4"
            />
            <span class="d-flex flex-row">
              <v-text-field
                            dense
                            label="SKU (Model Number)"
                            outlined
                            v-if="data.type==='simple'"
                            v-model="data.sku"
                            @focus="lockScanBusForSKUBC"
                            @blur="unlockScanBusForSKUBC"
                            @change="trimBC"
              />
            </span>
            <v-text-field
                          dense
                          label="Barcode"
                          outlined
                          v-if="data.type==='simple'"
                          v-model="data.barcode"
            />
            <v-textarea
                          label="Description"
                          dense
                          outlined
                          v-model="data.description"
            />
            <v-text-field
                          dense
                          label="Regular Price"
                          outlined
                          type="number"
                          v-if="data.type==='simple'"
                          v-model="data.regularPrice"
            />
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <v-dialog scrollable v-model="createSupplierDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Supplier
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field dense @input="resetExisting" :rules="nameRules" v-model="newSupplier.name" label="Name" outlined></v-text-field>
          <v-text-field dense @input="resetExisting" v-model="newSupplier.email" label="Email" outlined></v-text-field>
          <v-text-field dense @input="resetExisting" v-model="newSupplier.phone" label="Phone" outlined></v-text-field>
          <div v-if="existingData.length>0">
            <p class="red--text">Similar Suppliers already exist. Are you sure you want to create another?</p>
            <h3>Or Choose A Similar Supplier</h3>
            <v-btn class="mx-1 my-1" color="info" @click="existingSupplierSelected(item)" v-for="(item, i) in existingData" :key="i">{{item.name}}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCreateSupplier">
            Cancel
          </v-btn>
          <v-btn v-if="existingData.length===0" :disabled="!newSupplier.name" class="primary" text @click="createSupplier">
            Create
            <v-progress-circular
                indeterminate
                color="green"
                v-if="supplierLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
          <v-btn v-else class="primary" text @click="confirmCreateSupplier">
            Create New
            <v-progress-circular
                indeterminate
                color="green"
                v-if="supplierLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createBrandDialog" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Create Brand
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-text-field dense @input="resetExisting" :rules="nameRules" v-model="newBrand.name" label="Name" outlined></v-text-field>
          <div v-if="existingData.length>0">
            <p class="red--text">Similar Brands already exist. Are you sure you want to create another?</p>
            <h3>Or Choose A Similar Brand</h3>
            <v-btn class="mx-1 my-1" color="info" @click="existingBrandSelected(item)" v-for="(item, i) in existingData" :key="i">{{item.name}}</v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeCreateBrand">
            Cancel
          </v-btn>
          <v-btn v-if="existingData.length===0" :disabled="!newBrand.name" class="primary" text @click="createBrand">
            Create
            <v-progress-circular
                indeterminate
                color="green"
                v-if="brandLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
          <v-btn v-else class="primary" text @click="confirmCreateBrand">
            Create New
            <v-progress-circular
                indeterminate
                color="green"
                v-if="brandLoading"
                style="margin-left: 10px;"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createAttributeDialog" width="600">
      <v-card>
        <v-card-title class="text-h5">
          Create New Attribute
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-card outlined class="spacedCard">
            <v-text-field dense
              label="Name"
              outlined
              v-model="newAttribute.name"
            />
            <v-combobox
              multiple
              outlined
              v-model="newAttribute.values"
              label="Values"
              chips
              deletable-chips
              clearable
              persistent-hint
              hint="Press Enter to separate values."
            />
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeNewAttributeDialog">
            Cancel
          </v-btn>
          <v-btn class="success" :disabled="newAttribute.name.length===0" @click="saveAttribute" text :loading="attrLoading">Save Attribute</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="createTermsDialog" width="600">
      <v-card>
        <v-card-title class="text-h5">
          Create New Terms
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-card outlined class="spacedCard">
            <v-combobox
                multiple
                outlined
                v-model="newTerms.values"
                label="Values"
                chips
                deletable-chips
                clearable
                persistent-hint
                hint="Press Enter to separate values."
            />
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeNewTermsDialog">Cancel</v-btn>
          <v-btn class="success" text :disabled="newTerms.values.length===0" @click="saveAttributeTerms" :loading="termsLoading">Save Terms</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog scrollable v-model="imageData.isOpen" width="600">
      <v-card>
        <v-card-title class="text-h5">
          Modify Image
        </v-card-title>
        <v-card-text style="padding-top: 20px;">
          <v-card outlined class="spacedCard">
            <v-text-field label="Name (Optional)" v-model="imageData.data.name" outlined dense/>
            <v-autocomplete
                multiple
                outlined
                v-model="imageData.data.tags"
                :items="mediaTags"
                label="Tags"
                chips
                deletable-chips
                clearable
            />
          </v-card>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error" text @click="closeImageSettings">Cancel</v-btn>
          <v-btn class="success" @click="updateImage" :loading="imageData.loading">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="printDialog.isOpen"
      width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Print Barcodes
        </v-card-title>
        <v-card-text>
          <h3 style="margin: 0px;">Select item to print</h3>
          <hr>
          <v-select
            class="mt-2"
            :items="filteredPrinters"
            item-text="name"
            item-value="id"
            label="Select a Printer"
            v-model="printDialog.printer"
            outlined
          ></v-select>
          <v-text-field dense style="margin-top: 10px;" v-model="printDialog.quantity" type="number" default="1" label="Quantity" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closePrintDialog()">
            Cancel
          </v-btn>
          <authorizer 
            v-bind:buttontext="'Print'" 
            v-bind:small="true" 
            v-on:response="attemptPrint($event)"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="deleteProductDialog.isOpen" width="500">
      <v-card>
        <v-card-title class="text-h5">
          Confirm Delete Product
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="info" text @click="closeDeleteProductDialog">
            Cancel
          </v-btn>
          <v-btn class="error" text @click="deleteProduct">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>

  </div>
</template>

<style>
.spacedCard{
  padding: 20px;
  margin-bottom: 20px;
}
</style>

<script>
import axios from 'axios';
import { mapGetters, mapMutations } from 'vuex'
import authorizer from '../../components/authorizer.vue'
import confirmedActionButton from '../../components/confirmedActionButton.vue'
export default {
  components: {
    authorizer,
    confirmedActionButton
  },
  data () {
    return {
      printers: [],
      filteredPrinters: [],
      documentTypes: [],
      snackObj: {
        state: false,
        color: '',
        text: ''
      },

      isEditable: false,
      loader: false,
      updating: false,
      max25chars: v => v.length <= 25 || 'Input too long!',
      data: {},
      productTypeValues: [
        {name: "Simple", value: "simple"}, {name: "Variable", value: "variable"}
      ],

      imageUploader: null,

      isThereVariations: false,

      attributeColors: ['warning', 'info', 'success', 'secondary', 'primary'],

      variationPanels: [],

      selectedCategories: [],
      selectedTags: [],
      selectedSuppliers: [],
      selectedAttributes: [],

      categories: [],
      tags: [],
      images: [],
      attributes: [],
      brands: [],
      suppliers: [],
      variations: [],

      createSupplierDialog: false,
      newSupplier: {},
      supplierLoading: false,

      createBrandDialog: false,
      brandLoading: false,
      newBrand: {},

      createAttributeDialog: false,
      newAttribute: {
        name: "",
        values: []
      },
      attrLoading: false,

      createTermsDialog: false,
      newTerms: {
        attribute: null,
        values: []
      },
      termsLoading: false,

      nameRules: [
        v => !!v || 'Name is required'
      ],

      products: [],

      existingData: [],

      imageData: {
        isOpen: false,
        data: {tags: [], type: 'image', name: ''},
        product: null,
        loading: false,
        img: null
      },

      mediaTags: [],

      singular: "Inventory",
      singularLower: "inventory",
      plural: "Inventories",
      pluralLower: "inventories",
      printDialog: {
        isOpen: false,
        quantity: 1,
        jobType: 'productLabel',
        deliveryNote: '',
        printer: '',
      },
      registerSKUBarcodeDialog: {
        isOpen: false,
        value: "",
        loading: false,
      },
      deleteProductDialog: {
        isOpen: false,
      }
    }
  },
  // watch: {
  //   scanBus: {
  //     handler(){
  //       console.log("Scanbus Changed")
  //       if(this.scanBus.handler == 'SKU'){
  //         this.SKUHandler()
  //       }
  //     },
  //     deep: true
  //   }
  // },
  async mounted(){
    window.scrollTo({top: 0});
    await this.getAllData();
  },
  computed: {
    ...mapGetters(['getEndpoint', "isAllowed", 'getUser', 'getGlobalValue', 'scanBus'])
  },
  methods: {
    ...mapMutations([
      "setScanBus",
      "resetScanBus",

    ]),
    snack(text, color=""){
      this.snackObj.text = text;
      this.snackObj.state = true;
      this.snackObj.color = color;
    },
    // SKUHandler(){
    //   this.scanBus.data
    // },
    lockScanBusForSKUBC(){
      this.setScanBus({handler: "SKU"})
    },
    unlockScanBusForSKUBC(){
      this.resetScanBus()
    },
    trimBC(){
      this.data.sku = this.data.sku.replace(/\\\/\/\\=/,'')
      console.log('Trimmed')
    },
    async deletePhoto(index){
      try {
        if(this.data.metadata && this.data.metadata.media){
          let x = await axios.post(`${this.getEndpoint}/api/products/deleteImage`, {pId: this.data.id, url: this.data.metadata.media[index].url})
          if(x.data.success){
            this.data.metadata.media.splice(index,1)
          }
        }
      } catch (error) {
        console.log(error)
      }
    },    
    async update(){
      try {
        this.updating = true;
        this.isEditable = false;
        this.loader = true;

        let obj = {
          ...this.data,
          suppliers: this.selectedSuppliers,
          categories: this.selectedCategories,
          tags: this.selectedTags,
          attributes: this.selectedAttributes,
          variations: this.variations
        };

        obj.regularPrice = parseFloat(obj.regularPrice);
        obj.salePrice = parseFloat(obj.salePrice);
        obj.suppliers.forEach(supplier => {
          supplier.costPrice = parseFloat(supplier.costPrice)
        });
        obj.variations.forEach(variation => {
          variation.regularPrice = parseFloat(variation.regularPrice);
          variation.salePrice = parseFloat(variation.salePrice);
          variation.selectedSuppliers.forEach(supplier => {
            supplier.costPrice = parseFloat(supplier.costPrice)
          });
        });

        console.log(obj)

        let res = await axios.put(`${this.getEndpoint}/api/products/${this.$route.params.id}`, obj)
        if(res.data.error) throw res.data.error

        this.snack("Product Updated.", "success")

        await this.getAllData();
      } catch (error) {
        this.isEditable = true;
        console.error(error)
        this.snack(error.msg || error, "error");
      } finally {
        this.loader = false;
        this.updating = false;
      }
    },
    deleteProductA(){
      this.deleteProductDialog.isOpen = true
    },
    closeDeleteProductDialog(){
      this.deleteProductDialog.isOpen = false
    },
    async deleteProduct(){
      try {
        this.loader = true
        let res = await axios.delete(`${this.getEndpoint}/api/products/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error

        this.snack("Product Deleted", "success");
        await this.$router.go(-1);
      } catch (error) {
        console.error(error)
      }
    },
    productTypeChanged(){
      this.checkIfVariationsExist();
    },
    openNewAttributeDialog(){
      this.createAttributeDialog = true;
    },
    closeNewAttributeDialog(){
      this.createAttributeDialog = false;
      this.newAttribute = {
        name: "",
        values: []
      }
    },
    async saveAttribute(){
      try{
        this.attrLoading = true;

        let res = await axios.post(`${this.getEndpoint}/api/productattributes/addAttributeWithTerms`, this.newAttribute);
        if(res.data.error) throw res.data.error;

        this.selectedAttributes.push(res.data.data);

        this.closeNewAttributeDialog();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.attrLoading = false;
      }
    },
    openNewTermsDialog(i){
      this.createTermsDialog = true;
      this.newTerms = {
        attribute: i,
        values: []
      }
    },
    closeNewTermsDialog(){
      this.createTermsDialog = false;
      this.newTerms = {
        attribute: null,
        values: []
      }
    },
    updateSelectedAttributes(){
      for(let attr of this.selectedAttributes){
        if(attr.selectedTerms===undefined) attr.selectedTerms = [];
        if(attr.variation===undefined) attr.variation = false;
        if(attr.visible===undefined) attr.visible = true;
      }

      for(let variation of this.variations){
        for(let i=variation.selectedAttributes.length-1;i>=0;i--){
          if(!this.selectedAttributes.find(x => x.id===variation.selectedAttributes[i].id)){
            variation.selectedAttributes.splice(i, 1);
          }
        }
      }
    },
    updateSelectedTerms(){
      this.$forceUpdate();
      this.checkIfVariationsExist();
    },
    async saveAttributeTerms(){
      try{
        this.attrLoading = true;

        for(let newTerm of this.newTerms.values){
          let existingTerm = this.selectedAttributes[this.newTerms.attribute].ProductAttributeTerms.find(term => {
            return term.name.toLowerCase()===newTerm.toLowerCase();
          });

          if(existingTerm) throw `The term '${existingTerm.name}' already exists in '${this.selectedAttributes[this.newTerms.attribute].name}'`;
        }

        let createdTerms = await axios.post(`${this.getEndpoint}/api/productattributes/addTermsToAttribute/${this.selectedAttributes[this.newTerms.attribute].id}`, {terms: this.newTerms.values});
        if(createdTerms.data.error) throw createdTerms.data.error

        this.selectedAttributes[this.newTerms.attribute].ProductAttributeTerms = this.selectedAttributes[this.newTerms.attribute].ProductAttributeTerms.concat(createdTerms.data.data);
        this.selectedAttributes[this.newTerms.attribute].selectedTerms = this.selectedAttributes[this.newTerms.attribute].selectedTerms.concat(createdTerms.data.data);

        this.closeNewTermsDialog();
      }
      catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.attrLoading = false;
      }
    },
    checkIfVariationsExist(){
      if(this.data.type==="variable"){
        for(let attr of this.selectedAttributes){
          if(attr.variation===true){
            this.isThereVariations = true;
            this.updateVariationTerms();
            this.$forceUpdate();
            return;
          }
        }
      }
      this.isThereVariations = false;
    },
    createVariation(){
      let variation = {
        id: null,
        name: null,
        parent: this.data.id,
        sku: "",
        regularPrice: 0.00,
        salePrice: 0.00,
        type: "variation",
        selectedAttributes: [],
        selectedSuppliers: [],
        metadata: {
          customName: false
        }
      };

      this.variations.push(variation);
      this.updateVariationTerms();
      this.updateVariationSuppliers();
    },
    removeVariation(i){
      this.variations.splice(i, 1);
    },
    updateVariationTerms(){
      this.variations.forEach(variation => {
        this.selectedAttributes.forEach(attr => {
          if(attr.variation){
            let found = variation.selectedAttributes.find(x => x.id===attr.id);
            if(!found){
              let obj = {
                id: attr.id,
                name: attr.name,
                selectedTerms: [],
                variation: attr.variation,
                visible: attr.visible,
                selectedTerm: null
              }

              attr.selectedTerms.forEach(x => {
                obj.selectedTerms.push({...x})
              });

              variation.selectedAttributes.push(obj);
            }
            else{
              found.selectedTerms = [];
              attr.selectedTerms.forEach(x => {
                found.selectedTerms.push({...x})
              });
              found.variation = attr.variation;
              found.visible = attr.visible;
            }
          }
        });
        for(let i=variation.selectedAttributes.length-1; i>=0; i--){
          let attr = variation.selectedAttributes[i];
          let found = this.selectedAttributes.find(x => x.id===attr.id);
          if((found && !found.variation) || !found){
            variation.selectedAttributes.splice(i, 1);
          }
        }
      });
    },
    updateVariationSuppliers(){
      if(this.data.type==='variable'){
        this.variations.forEach(variation => {
          let newArr = [];
          this.selectedSuppliers.forEach(supplier => {
            let found = variation.selectedSuppliers.find(x => x.id===supplier.id);
            let cp = 0;
            if(found) cp=found.costPrice;
            newArr.push({id: supplier.id, name: supplier.name, costPrice: cp})
          });
          variation.selectedSuppliers = newArr;
        });
      }
    },
    async getAllData(){
      try {
        this.loader = true;

        let res = await axios.get(`${this.getEndpoint}/api/products/findByIdV1/${this.$route.params.id}`)
        if(res.data.error) throw res.data.error

        this.attributes = res.data.data.attributes;
        this.brands = res.data.data.brands;
        this.categories = res.data.data.categories;
        this.data = res.data.data.data;
        this.suppliers = res.data.data.suppliers;
        this.tags = res.data.data.tags;
        this.variations = res.data.data.variations;
        this.selectedAttributes = res.data.data.selectedAttributes;
        this.customTerms = res.data.data.customTerms;

        if(this.data.type==="variation"){
          await this.$router.push({path: `/`});
        }

        //test code to auto open all the variations
        //for(let i=0;i<this.variations.length;i++) this.variationPanels.push(i);

        this.checkIfVariationsExist();

        this.selectedCategories = res.data.data.data.ProductCategories.map(x => x.id);
        this.selectedTags = res.data.data.data.ProductTags.map(x => x.id);
        this.selectedSuppliers = res.data.data.selectedSuppliers;

        let userPrinters = this.getUser.metadata.printers
        let printers = await axios.get(`${this.getEndpoint}/api/devices/`)
        if(!printers.data.error){
          this.documentTypes = printers.data.data.documentTypes
          let printerDeviceTypeId = (printers.data.data.deviceTypes.filter(x=>x.name.toLowerCase()==='printer')[0]).id //looks at the deviceType object from the response to determine the id of the printers device type
          this.printers = printers.data.data.devices.filter(x=>x.deviceType===printerDeviceTypeId)
          this.printers.forEach(printer=>{
            if(userPrinters && userPrinters.default){
              for(let y in userPrinters.default){
                if(printer.data.jobTypes.includes(y)){
                  if(printer.id==userPrinters.default[y]){
                    printer['isDefault'] = true
                    printer.name = printer.name + ` (Default ${y} printer)`
                  }else printer['isDefault'] = false
                }
              }
            }
          })
          this.filteredPrinters = this.printers
        }

        this.mediaTags = this.getGlobalValue('mediaTags')?.split(",") || [];

      } catch (error) {
        console.error(error);
        this.snack(error.msg || error, "error");
      } finally {
        this.loader = false;
      }
    },
    closeCreateSupplier(){
      this.createSupplierDialog = false;
      this.newSupplier = {};
      this.supplierLoading = false;
      this.existingData = [];
    },
    async createSupplier(){
      try{
        this.supplierLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/suppliers`, this.newSupplier)
        if(res.data.error) throw res.data.error

        if(res.data.data.created){
          this.snack("Supplier Created", "success");
          let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers/lite`)
          if(suppliers.data.error) throw suppliers.data.error
          this.suppliers = suppliers.data.data;
          this.selectedSuppliers.push({id:res.data.data.data.id, name: res.data.data.data.name, costPrice: 0.00})
          this.closeCreateSupplier();
        }
        else{
          this.existingData = res.data.data.data;
          this.snack("Similar Suppliers Found.");
        }
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error, "error");
      }
      finally {
        this.supplierLoading = false;

      }
    },
    existingSupplierSelected(supplier){
      this.selectedSuppliers.push(supplier.id)
      this.closeCreateSupplier();
    },
    async confirmCreateSupplier(){
      try {
        this.supplierLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/suppliers/confirmCreate`, this.newSupplier)
        if(res.data.error) throw res.data.error

        this.snack("Supplier Created", "success");
        let suppliers = await axios.get(`${this.getEndpoint}/api/suppliers/lite`)
        if(suppliers.data.error) throw suppliers.data.error
        this.suppliers = suppliers.data.data;
        this.selectedSuppliers.push({id:res.data.data.data.id, name: res.data.data.data.name, costPrice: 0.00})
        this.closeCreateSupplier();
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.supplierLoading = false;
      }
    },
    closeCreateBrand(){
      this.createBrandDialog = false;
      this.newBrand = {};
      this.brandLoading = false;
      this.existingData = [];
    },
    async createBrand(){
      try{
        this.brandLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/brands`, this.newBrand)
        if(res.data.error) throw res.data.error

        if(res.data.data.created){
          this.snack("Brand Created", "success");
          let brands = await axios.get(`${this.getEndpoint}/api/brands/lite`)
          if(brands.data.error) throw brands.data.error
          this.brands = brands.data.data;
          this.data.brandId = res.data.data.data.id;
          this.closeCreateBrand();
        }
        else{
          this.existingData = res.data.data.data;
          this.snack("Similar Brands Found.");
        }
      }
      catch (error){
        console.error(error);
        this.snack(error.msg || error, "error");
      }
      finally {
        this.brandLoading = false;
      }
    },
    existingBrandSelected(brand){
      this.data.brandId = brand.id;
      this.closeCreateBrand();
    },
    async confirmCreateBrand(){
      try {
        this.brandLoading = true;
        let res = await axios.post(`${this.getEndpoint}/api/brands/confirmCreate`, this.newBrand)
        if(res.data.error) throw res.data.error

        this.snack("Brand Created", "success");
        let brands = await axios.get(`${this.getEndpoint}/api/brands/lite`)
        if(brands.data.error) throw brands.data.error
        this.brands = brands.data.data;
        this.data.brandId = res.data.data.data.id;
        this.closeCreateBrand();
      } catch (error) {
        console.error(error)
        this.snack(error.msg || error, "error");
      }
      finally {
        this.brandLoading = false;
      }
    },
    resetExisting(){
      this.existingData = [];
    },
    openPrintDialog(){
      this.printDialog.isOpen = true
    },
    closePrintDialog(){
      this.printDialog = {
        isOpen: false,
        quantity: 1,
        jobType: '',
        deliveryNote: '',
        printer: ''
      }
    },
    async attemptPrint(e){
      try {
          if(!e.pin&&!e.vsidToken) throw "Error Getting Credentials"
          e.metadata = this.printDialog
          let res = await axios.post(`${this.getEndpoint}/api/print/productLabel/${this.$route.params.id}`, e)
          if(res.data.error) throw res.data.error
          this.closePrintDialog()
      } catch (error) {
        console.log(error)
        this.snack(error)
        this.closePrintDialog()
      }
    },
    async uploadImage(file, product){
      try {
        if(!product.id) throw "Product ID is missing."
        this.loader = true;
        this.imgToUpload = file
        let theFile = new FormData()
        theFile.append('image', this.imgToUpload, "image")
        let res = await axios.post(`${this.getEndpoint}/api/products/uploadImages/${product.id}`, theFile)
        if(res.data.success){
          this.snack("✅ Image Updated.", "success")
          product.metadata.media = res.data.data;
          file = null;
          this.imageUploader = null;
        }else throw res.data.error
      } catch (error) {
          console.log(error)
          this.snackText = error
          this.snackBar = true;        
      } finally {
        this.loader = false
        this.imgToUpload = null
      }
    },
    async deleteImage(img, product){
      try {
        if(!product.id) throw "Product ID is missing."
        this.loader = true;

        let verify = confirm("Are you sure you want to delete this image? This action cannot be reversed.");
        if(!verify) return;

        let res = await axios.post(`${this.getEndpoint}/api/products/deleteImage`, {url: img.url, pId: product.id})
        if(res.data.success){
          this.snack("✅ Image Deleted.", "success")
          product.metadata.media = res.data.data;
        }
        else 
          throw "Error: Image was not deleted."
      } catch (error) {
        this.snackText = error
        this.snackBar = true;
      } finally {
        this.loader = false
      }
    },
    clearScanString(){
      this.scanString = ''
      console.log("🔁 Scan String Cleared")
    },
    clearScanStringTimeout(){
      this.timeout = setTimeout(this.clearScanString,500)
    },
    cancelClearScanStringTimeout(){
      clearTimeout(this.timeout)
    },
    async updateImage(){
      try {
        this.imageData.loading = true;
        if(!this.imageData?.product?.id) throw "Product ID is missing."
        if(!this.imageData?.img) throw "Image Data is missing."
        if(!this.imageData?.data) throw "Image New Data is missing."

        let res = await axios.put(`${this.getEndpoint}/api/products/updateImage`, {url: this.imageData.img.url, pId: this.imageData.product.id, data: this.imageData.data})
        if(res.data.error) throw res.data.error;

        this.snack("✅ Image Updated.", "success");
        this.imageData.product.metadata.media = res.data.data;

      } catch (error) {
        console.log(error)
        this.snackText = error
        this.snackBar = true;
      } finally {
        this.imageData.loading = false;
        this.closeImageSettings();
      }
    },
    openImageSettings(img, product){
      this.imageData.data = {tags: img.tags, type: img.type, name: img.data.name || ''};
      this.imageData.isOpen = true;
      this.imageData.img = img;
      this.imageData.product = product;
    },
    closeImageSettings(){
      this.imageData.isOpen = false;
      this.imageData.data = {tags: [], type: 'image', name: ''};
      this.imageData.img = null;
      this.imageData.product = null;
    },
    openImage(url){
      window.open(url, '_blank').focus();
    }
  }
}
</script>